<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" notes="Internal purposes only." />
			<app-input-text label="Weight" placeholder="Enter weight..." v-model="model.weight" :validation="$v.model.weight" notes="You can increase the weighting of this QR code so that is has more value than other codes." />
			<app-input-toggle label="Multiple Scans" v-model="model.multi.enabled" :validation="$v.model.multi.enabled" notes="Can a single user scan the QR code more than once during the course of the contest." />
			<app-input-text v-if="model.multi.enabled" label="Cooldown Period" placeholder="Enter minutes..." :toggle="model.cooldown.enabled" v-on:toggle="model.cooldown.enabled = !model.cooldown.enabled" v-model="model.cooldown.duration" :validation="$v.model.cooldown.duration" notes="How frequently can the QR code be scanned by the same user in minutes." />
			<app-input-text label="Contest Limit" placeholder="Enter limit..." :toggle="model.limit.enabled" v-on:toggle="model.limit.enabled = !model.limit.enabled" v-model="model.limit.amount" :validation="$v.model.limit.amount" notes="Is there an absolute limit on the total number of times this QR code can be scanned by all users." />
			<app-input-text label="Notification" :autogrow="true" placeholder="Enter message..." :toggle="model.message.enabled" v-on:toggle="model.message.enabled = !model.message.enabled" v-model="model.message.text" :validation="$v.model.message.text" notes="Add custom text to the notification received by a user when they scan this QR code." />
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			model: {
				id: false,
				name: '',
				weight: 1,
				cooldown: {
					enabled: false,
					duration: 0
				},
				limit: {
					enabled: false,
					amount: 0
				},
				multi: {
					enabled: false
				},
				message: {
					enabled: false,
					text: ''
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			weight: {

			},
			cooldown: {

			},
			limit: {

			},
			multi: {

			},
			message: {

			}
		}
	}

}

</script>

<style scoped>

</style>